import { useLocation } from "react-router";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import { AppRoutes } from "./Routes/AppRoutes";
import { ROUTES } from "./Constants/Routes";
import { theme } from "./Theme";
import { ThemeProvider } from "styled-components";
import { createContext, useEffect, useMemo, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { StyledToastContainer } from "./GlobalStyles";
import "react-toastify/dist/ReactToastify.css";
import { setToken } from "./Services/Auth";
import { useDispatch } from "react-redux";
import CustomRedirect from "./Components/CustomRedirect/CustomRedirect";

export const ThemeContext = createContext();

function App() {
  const [currentTheme, setCurrentTheme] = useState(
    localStorage.getItem("currentTheme")
  );
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("currentTheme")) {
      localStorage.setItem("currentTheme", "light");
      setCurrentTheme("light");
    }
    if (localStorage.getItem("token")) {
      dispatch(setToken({ token: JSON.parse(localStorage.getItem("token")) }));
    }
  }, [dispatch]);

  const themeContextValue = useMemo(
    () => ({ currentTheme, setCurrentTheme }),
    [currentTheme, setCurrentTheme]
  );
  return (
    <CookiesProvider>
      <ThemeContext.Provider value={themeContextValue}>
        <ThemeProvider
          theme={currentTheme === "light" ? theme.light : theme.dark}
        >
          <div
            className="App"
            style={{
              background:
                currentTheme === "light"
                  ? theme.light.colors.white
                  : theme.dark.colors.white,
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CustomRedirect
              path={[ROUTES.SPOT]}
              redirectUrl={`${ROUTES.SPOT}/TOMO3L`}
            />
            {/* Toaster Component */}
            <StyledToastContainer />
            <Navbar />

            {/* Routes of app*/}
            <AppRoutes style={{ flex: "1" }} />

            {/* Hiding Footer for specific routes */}
            {!(
              location.pathname?.split("/")?.[1] ===
                ROUTES.LOGIN?.split("/")?.[1] ||
              location.pathname?.split("/")?.[1] ===
                ROUTES.SIGNUP?.split("/")?.[1] ||
              location.pathname?.split("/")?.[1] ===
                ROUTES.RESETPASSWORD?.split("/")?.[1]
            ) && <Footer />}
          </div>
        </ThemeProvider>
      </ThemeContext.Provider>
    </CookiesProvider>
  );
}

export default App;
