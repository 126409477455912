// Routes name used in the app
export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  SIGNUP: "/signup",
  ABOUT: "/About",
  RESETPASSWORD: "/reset-password",
  MARKET: "/markets",
  MARKETOVERVIEW: "/markets/data",
  COINDETAILS: "/info/:id",
  KYC: "/kyc",
  SPOT: "/spot",
  FIAT: "/trade-crypto",
  BUYCURRENCY: "/trade-crypto/buy",
  SELLCURRENCY: "/trade-crypto/sell",
  PERPETUAL: "/trade-swap",
  CURRENTSPOT: "/spot/current",
  SETTINGS: "/my/info",
  ACCOUNTSETTINGS: "basic",
  PREFERENCESETTINGS: "preference",
  FORBID: "basic/forbid",
  CANCEL: "basic/cancel",
  FORGOTPASSWORD: "/forgot-password",
  ASSET: "/asset",
  ASSETWITHDRAW: "/asset/withdraw-records",
  ASSETDEPOSIT: "/asset/deposit-records",
  DEPOSIT: "/asset/deposit",
  WITHDRAW: "/asset/withdraw",
  BOT_LANDING: "/bot",
  STRATEGY: "/spot-strategy",
  SWAP_STRATEGY: "/swap-strategy",
  ORDER_DETAIL: "/order-detail",
  BOT_ORDER_HISTORY: "/bot/order-history",
  PAYMENT: "/trade-crypto/payment",
  ORDER: "/orders",
  RECURRING_ORDERS: "/recurring-orders",
  TWAP_ORDERS: "/twap-orders",
};
