import styled from "styled-components";
import { deviceQuery } from "../../../../../../MediaSizes";

export const StyledRecurringBuy = styled.div`
  background-color: ${(props) => props.theme.colors.blue.extraLight};
  .recurring-container {
    display: flex;
    flex-direction: column;
    width: 85%;
    margin: auto;
    gap: 30px;
  }
  .ant-breadcrumb .ant-breadcrumb-separator {
    color: ${(props) => props.theme.colors.black};
  }
  .breadcrumbs {
    display: flex;
    align-items: center;
  }
  .ant-breadcrumb ol {
    align-items: center !important;
  }
  .mini-nav {
    display: flex;
    flex-direction: column;
    span {
      color: ${(props) => props.theme.colors.black};
    }
  }
  .title-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .stop-btn {
      background: transparent !important;
    }
    .left {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      span {
        font-size: 18px;
      }
      p {
        font-size: 14px;
        color: ${(props) => props.theme.colors.grey.dark};
      }
    }
  }
  label {
    font-size: 20px;
    color: ${(props) => props.theme.colors.grey.dark};
  }
  span {
    font-size: 16px;
  }
  .pnl-container {
    width: 100%;
    height: 170px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;

    .upper-container {
      display: flex;
      justify-content: space-between;
      .left,
      .right {
        display: flex;
        flex-direction: column;
        p {
          font-size: 20px;
          border-bottom: 1px dashed ${(props) => props.theme.colors.black};
          font-weight: 600;
          color: ${(props) => props.theme.colors.black};
        }
        span {
          font-size: 28px;
        }
      }
      .left {
        align-items: baseline;
      }
      .right {
        align-items: end;
      }
    }
    .lower-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .card {
        display: flex;
        gap: 10px;
      }
      span {
        color: ${(props) => props.theme.colors.black};
      }
    }
  }
  .details-container {
    width: 100%;
    height: 300px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    .head {
      display: flex;
      justify-content: left;
      p {
        font-size: 22px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.black};
      }
    }
    .table-container {
      width: 98%;
      margin: auto;
      display: grid;
      grid-template-columns: 1.5fr 1fr;
      /* flex: 2 ; */
      justify-content: space-between;
      gap: 10px;
      height: 96%;
      table {
        max-width: 300px !important;
        color: ${(props) => props.theme.colors.black};
      }
      .ant-table-wrapper {
        max-width: 700px !important;
      }
      .ant-table-thead > tr > th {
        background: ${(props) => props.theme.colors.blue.extraLight} !important;
        border: none !important;
        color: ${(props) => props.theme.colors.black};
      }
      .ant-table-cell::before {
        display: none !important;
      }

      .scroll-table {
        /* width: 100%; */
        overflow: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none;
        height: 90%;
      }
      .scroll-table::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .history-container {
    width: 100%;
    height: 600px;
    background: ${(props) => props.theme.colors.white};
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 40px;
    table {
      width: 98%;
      margin: auto;
      color: ${(props) => props.theme.colors.black};
    }
    .ant-table-thead > tr > th {
      background: ${(props) => props.theme.colors.blue.extraLight} !important;
      border: none !important;
      color: ${(props) => props.theme.colors.black};
    }
    .ant-table-cell::before {
      display: none !important;
    }
  }
  .head {
    display: flex;
    justify-content: left;
    p {
      font-size: 22px;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black};
    }
  }
  .static-table {
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .static-table::-webkit-scrollbar {
    display: none;
  }

  .ant-table-wrapper
    .ant-table-tbody
    .ant-table-row
    > .ant-table-cell-row-hover {
    background: ${(props) => props.theme.colors.blue.extraLight} !important;
  }
  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row > td {
    /* background: ${(props) =>
      props.theme.colors.blue.extraLight} !important; */
    transition: none !important;
  }

  @media (max-width: 1000px) {
    .details-container {
      height: 600px;
      .table-container {
        grid-template-columns: 1fr;
      }
    }
    .mini-nav {
      span {
        font-size: 15px !important;
      }
      .breadcrumbs {
      }
    }
  }
  @media (max-width: 500px) {
    .mini-nav {
      span {
        font-size: 15px !important;
      }
    }
    .pnl-container {
      gap: 10px;
      .upper-container {
        p,
        span {
          font-size: 18px !important;
        }
      }
      .lower-container {
        .card {
          label,
          span {
            font-size: 18px !important;
          }
        }
      }
    }
  }
`;
