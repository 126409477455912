import React, { useEffect, useState } from "react";
import { StyledRecurringBuy } from "./RecurringBuyDetails.styles";

import { Breadcrumb, Button, Divider, Table } from "antd";
import { BOT } from "../../../../../../Enums/Enums";
import RunTime from "../../../../../../Components/Bots/OrderDetail/Components/RunTime";
import {
  useGetRecurringOrderQuery,
  useGetRecurringSubOrderQuery,
} from "../../../../../../Services/Bot";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import DetailsModal from "../../../../../../Components/Bots/OrderDetail/DetailsModal/DetailsModal";
import { useTheme } from "styled-components";

const COINS_COLUMN = [
  {
    title: "Crypto | Target allocation",
    dataIndex: "recurringList",
    key: "recurringList",
    render: (item, row) => {
      return (
        <div>
          {row?.ccy} | {row?.ratio * 100}%
        </div>
      );
    },
  },
  {
    title: "Cumulated buy",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "Average price",
    dataIndex: "avgPx",
    key: "avgPx",
  },
  {
    title: "Market price",
    dataIndex: "px",
    key: "px",
  },
  {
    title: "PnL",
    dataIndex: "address",
    key: "address",
  },
];

const FREQUENCY_COLUMN = [
  {
    title: "Crypto",
    dataIndex: "investmentCcy",
    key: "investmentCcy",
  },
  {
    title: "Time",
    dataIndex: "cTime",
    key: "cTime",
    render: (item) => {
      const openTime = moment.duration(item);
      return (
        <div className="small-text self-bottom">
          <div>{moment(Number(openTime)).format("DD/MM/YYYY")}</div>
          <div>{moment(Number(openTime)).format("hh:mm:ss")}</div>
        </div>
      );
    },
  },
  {
    title: "Next purchase time",
    dataIndex: "nextInvestTime",
    key: "nextInvestTime",
    render: (item) => {
      const openTime = moment.duration(item);
      return openTime == "" ? (
        "-"
      ) : (
        <div className="small-text self-bottom">
          <div>{moment(Number(openTime)).format("DD/MM/YYYY")}</div>
          <div>{moment(Number(openTime)).format("hh:mm:ss")}</div>
        </div>
      );
    },
  },
  {
    title: "Amount",
    dataIndex: "amt",
    key: "amt",
  },
  {
    title: "Triggered times",
    dataIndex: "cycles",
    key: "cycles",
  },
];

const RecurringBuyDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const theme = useTheme();
  const breadCrumbItems = [
    { label: "Trading bots", key: -1 }, // remember to pass the key prop
    { label: "Order details", key: null },
  ];
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedDetailsRow, setSelectedDetailsRow] = useState(null);

  const {
    data: recurringOrder,
    isLoading: isRecurringOrderLoader,
    refetch: refetchRecurringOrder,
  } = useGetRecurringOrderQuery({
    algoId: id,
  });

  const { data: getRecurringSubOrder, isFetching: subOrderLoader } =
    useGetRecurringSubOrderQuery({
      algoId: id,
    });

  useEffect(() => {
    const interval = setInterval(() => {
      refetchRecurringOrder();
    }, 3000);
    return () => clearInterval(interval);
  }, [refetchRecurringOrder]);
  const HISTORY_COLUMN = [
    {
      title: "Symbol",
      dataIndex: "instId",
      key: "instId",
      width: 60,
    },
    {
      title: "Order time",
      dataIndex: "cTime",
      key: "cTime",
      render: (item, row) => {
        const openTime = moment.duration(item);
        return (
          <div className="small-text self-bottom">
            <div>{moment(Number(openTime)).format("DD/MM/YYYY")}</div>
            <div>{moment(Number(openTime)).format("hh:mm:ss")}</div>
          </div>
        );
      },
    },
    {
      title: "Side",
      dataIndex: "side",
      key: "side",
    },
    {
      title: "Fill price",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Filled Amount",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Status",
      dataIndex: "state",
      key: "state",
    },
    {
      key: "action",
      dataIndex: "action",
      title: "Action",
      render: (item, row) => {
        return (
          <button
            style={{
              color: "blue",
              fontSize: "16px",
              fontWeight: "500",
              cursor: "pointer",
              background: "transparent",
              boxShadow: "none",
              border: "none",
            }}
            onClick={() => {
              setIsDetailsModalOpen(true);
              setSelectedDetailsRow(row);
            }}
          >
            Details
          </button>
        );
      },
    },
  ];

  return (
    <StyledRecurringBuy>
      <div className="recurring-container">
        <div className="mini-nav">
          <div className="breadcrumbs">
            <Breadcrumb separator=">">
              {breadCrumbItems?.map((item, i) => {
                return (
                  <Breadcrumb.Item key={i}>
                    <span
                      onClick={() => {
                        if (item?.key) navigate(item?.key);
                      }}
                    >
                      {item.label}
                    </span>
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          </div>
          <div className="title-header">
            <RunTime
              orderDetails={recurringOrder}
              // setStopButtonModal={setStopButtonModal}
              selectedAlgoOrder="reccuring_buy"
            />
            {recurringOrder?.result?.data?.[0]?.state !== "stopped" ? (
              <Button
                className="stop-btn"
                onClick={() => setStopButtonModal(true)}
              >
                {recurringOrder?.result?.data?.[0]?.state === "running"
                  ? "Stop"
                  : "Cancel"}
              </Button>
            ) : (
              <Button
                disabled
                style={{
                  color: theme.colors.grey.light,
                  backgroundColor: theme.colors.grey.light,
                }}
              >
                {recurringOrder?.result?.data?.[0]?.state === "stopping"
                  ? "Stopped (Trigger Indicator)"
                  : "Stopped manually"}
              </Button>
            )}
          </div>
        </div>
        <div className="pnl-container">
          <div className="upper-container">
            <div className="left">
              <p>Total PnL</p>
              <span
                style={{
                  color:
                    recurringOrder?.result?.data?.[0]?.totalPnl > 0
                      ? theme.colors.marketUp
                      : theme.colors.marketDown,
                }}
              >
                {recurringOrder?.result?.data?.[0]?.totalPnl}
              </span>
            </div>
            <div className="right">
              <p>PnL%</p>
              <span
                style={{
                  color:
                    recurringOrder?.result?.data?.[0]?.pnlRatio > 0
                      ? theme.colors.marketUp
                      : theme.colors.marketDown,
                }}
              >
                {recurringOrder?.result?.data?.[0]?.pnlRatio * 100}%
              </span>
            </div>
          </div>
          <div className="lower-container">
            <div className="card">
              <label>Current market cap:</label>
              <span>
                {recurringOrder?.result?.data?.[0]?.mktCap}{" "}
                {recurringOrder?.result?.data?.[0]?.investmentCcy}
              </span>
            </div>
            <div className="card">
              <label>Cumulated recurring buy:</label>
              <span>12,222.25 USDT</span>
            </div>
          </div>
        </div>
        <div className="details-container">
          <div className="head">
            <p>Details</p>
          </div>
          <div className="table-container">
            <Table
              columns={COINS_COLUMN}
              className="scroll-table"
              dataSource={recurringOrder?.result?.data?.[0]?.recurringList}
              pagination={false}
              loading={isRecurringOrderLoader}
            />
            <Table
              columns={FREQUENCY_COLUMN}
              className="static-table"
              dataSource={recurringOrder?.result?.data}
              pagination={false}
              loading={isRecurringOrderLoader}
            />
          </div>
        </div>
        <div className="history-container">
          <div className="head">
            <p>History</p>
          </div>
          <Table
            columns={HISTORY_COLUMN}
            className="static-table"
            dataSource={getRecurringSubOrder?.result?.data}
            loading={subOrderLoader}
          />
        </div>
        <DetailsModal
          isDetailsModalOpen={isDetailsModalOpen}
          // setIsDetailsModalOpen={setIsDetailsModalOpen}
          row={selectedDetailsRow}
          recurringBuy="recurringBuy"
          // baseAsset={baseAsset}
          // quoteAsset={quoteAsset}
        />
      </div>
    </StyledRecurringBuy>
  );
};

export default RecurringBuyDetails;
