import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { config } from "./config";
import CryptoJS from "crypto-js";

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().global.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithEncryption = (args, api, extraOptions) => {
  if (args.body) {
    const encryptedBody = CryptoJS.AES.encrypt(
      JSON.stringify(args.body),
      config.AES_ENC_KEY
    );
    args.body = { data: encryptedBody.toString() };
  }
  return rtkBaseQuery(args, api, extraOptions);
};
