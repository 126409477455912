export const BOT = {
  SPOT: "grid",
  MOON_GRID: "moon_grid",
  FUTURE_GRID: "contract_grid",
  RECURRING_BUY: "recurring_buy",
  RUNNING: "running",
  STARTING: "starting",
  STOPPING: "stopping",
  NO_CLOSE_POSITION: "no_close_position",
  STOPPED: "stopped",
  PENDING_SIGNAL: "pending_signal",
  running: "Ongoing",
  starting: "Starting",
  stopping: "Stopping",
  no_close_position: "Paused unclosed",
  stopped: "Stopped",
  pending_signal: "Pending Signal",
  MANUAL: "Manual trading",
  TRADING_BOT: "Trading bots",
  contract_grid: "Future Hedge Grid",
  grid: "Spot Hedge Grid",
  moon_grid: "Moon Hedge Grid",
  recurring_buy: "Recurring Buy",
  twap: "TWAP",
  TWAP: "twap",
};
