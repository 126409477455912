import { useTheme } from "styled-components";

export const Logout = () => {
  const theme = useTheme();
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.513969 3.13759L6.52014 0.259611C6.95368 0.000191525 7.52005 0.309298 7.52005 0.827211V1.69173H11.9878C12.3527 1.69173 12.6484 1.98771 12.6484 2.35251V5.10648C12.6484 5.97569 11.3268 5.97569 11.3268 5.10648V3.0133H7.52002V12.9987H11.3268V10.9055C11.3268 10.0364 12.6484 10.0364 12.6484 10.9055V13.6595C12.6484 14.0245 12.3527 14.3203 11.9878 14.3203H7.52005L7.51737 15.1849C7.51777 15.6628 7.01553 15.9893 6.5753 15.7786L0.568787 12.9006C0.316599 12.8074 0.136719 12.5647 0.136719 12.2807L0.139397 3.73152C0.139665 3.48589 0.277747 3.25059 0.513866 3.13755L0.513969 3.13759Z"
        fill={theme.colors.Logout}
      />
    </svg>
  );
};
