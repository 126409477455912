import React, { useEffect, useState } from "react";
import { StyledRecurringBuy } from "./RecurringBuyForm.styles";
import { Button, Form, Input, Select, TreeSelect } from "antd";
import { useForm } from "antd/es/form/Form";
import RecurringItem from "./components/RecurringItem/RecurringItem.js";
import { PlusOutlined } from "@ant-design/icons";
import { useGetTokensQuery } from "../../../../Services/OKX.js";
import CoinIcons from "../../../../Constants/Coin.json";
import { useCreateRecurringBuyOrderMutation } from "../../../../Services/Bot.js";
import { toast } from "react-toastify";
import { useGetAccountInfoQuery } from "../../../../Services/Transaction.js";

const LOCAL_TIME_OPTIONS = [
  { value: "0", label: "00:00" },
  { value: "1", label: "01:00" },
  { value: "2", label: "02:00" },
  { value: "3", label: "03:00" },
  { value: "4", label: "04:00" },
  { value: "5", label: "05:00" },
  { value: "6", label: "06:00" },
  { value: "7", label: "07:00" },
  { value: "8", label: "08:00" },
  { value: "9", label: "09:00" },
  { value: "10", label: "10:00" },
  { value: "11", label: "11:00" },
  { value: "12", label: "12:00" },
  { value: "13", label: "13:00" },
  { value: "14", label: "14:00" },
  { value: "15", label: "15:00" },
  { value: "16", label: "16:00" },
  { value: "17", label: "17:00" },
  { value: "18", label: "18:00" },
  { value: "19", label: "19:00" },
  { value: "20", label: "20:00" },
  { value: "21", label: "21:00" },
  { value: "22", label: "22:00" },
  { value: "23", label: "23:00" },
];

const FREQUENCY_OPTIONS = [
  {
    title: "Hourly",
    value: "hourly",
    selectable: false,
    children: [
      {
        title: "1 hour",
        value: "hourly-1",
      },
      {
        title: "4 hour",
        value: "hourly-4",
      },
      {
        title: "8 hour",
        value: "hourly-8",
      },
      {
        title: "12 hour",
        value: "hourly-12",
      },
    ],
  },
  {
    title: "Daily",
    value: "daily",
  },
  {
    title: "Weekly",
    value: "weekly",
    selectable: false,
    children: [
      {
        title: "Mon",
        value: "weekly-1",
      },
      {
        title: "Tue",
        value: "weekly-2",
      },
      {
        title: "Wed",
        value: "weekly-3",
      },
      {
        title: "Thu",
        value: "weekly-4",
      },
      {
        title: "Fri",
        value: "weekly-5",
      },
      {
        title: "Sat",
        value: "weekly-6",
      },
      {
        title: "Sun",
        value: "weekly-7",
      },
    ],
  },
  {
    title: "Monthly",
    value: "monthly",
    selectable: false,
    children: [
      { title: "Day 1", value: "monthly-1" },
      { title: "Day 2", value: "monthly-2" },
      { title: "Day 3", value: "monthly-3" },
      { title: "Day 4", value: "monthly-4" },
      { title: "Day 5", value: "monthly-5" },
      { title: "Day 6", value: "monthly-6" },
      { title: "Day 7", value: "monthly-7" },
      { title: "Day 8", value: "monthly-8" },
      { title: "Day 9", value: "monthly-9" },
      { title: "Day 10", value: "monthly-10" },
      { title: "Day 11", value: "monthly-11" },
      { title: "Day 12", value: "monthly-12" },
      { title: "Day 13", value: "monthly-13" },
      { title: "Day 14", value: "monthly-14" },
      { title: "Day 15", value: "monthly-15" },
      { title: "Day 16", value: "monthly-16" },
      { title: "Day 17", value: "monthly-17" },
      { title: "Day 18", value: "monthly-18" },
      { title: "Day 19", value: "monthly-19" },
      { title: "Day 20", value: "monthly-20" },
      { title: "Day 21", value: "monthly-21" },
      { title: "Day 22", value: "monthly-22" },
      { title: "Day 23", value: "monthly-23" },
      { title: "Day 24", value: "monthly-24" },
      { title: "Day 25", value: "monthly-25" },
      { title: "Day 26", value: "monthly-26" },
      { title: "Day 27", value: "monthly-27" },
      { title: "Day 28", value: "monthly-28" },
    ],
  },
];

const RecurringBuy = () => {
  const [recurringForm] = useForm();

  const [coinOptions, setCoinOptions] = useState([]);
  const [remainingAllocation, setRemainingAllocation] = useState(100);
  const [recurringList, setRecurringList] = useState([
    { ccy: "BTC", allocation: 100 },
  ]);

  const { data: tokens } = useGetTokensQuery({ instType: "SPOT" });
  const [createRecurringBuyOrder, { isLoading: isCreatingRecurringBuyOrder }] =
    useCreateRecurringBuyOrderMutation();
  const { data: accountInfo } = useGetAccountInfoQuery();
  console.log("Account Info", accountInfo);

  useEffect(() => {
    if (!tokens) return;
    const coinOptions = tokens
      .filter((token) => !recurringList.some((e) => e.ccy == token))
      .map((token) => ({
        label: token,
        value: token,
        icon: CoinIcons[token],
      }));

    setCoinOptions(coinOptions);
  }, [tokens, recurringList]);

  useEffect(() => {
    let totalAllocation = 0;

    recurringList.forEach((item, index) => {
      recurringForm.setFieldValue(`alloc_${index}`, item.allocation);
      totalAllocation += item.allocation;
    });

    setRemainingAllocation(100 - totalAllocation);
  }, [recurringList, recurringForm]);

  const handleRecurringItemChange = (index, item) => {
    const list = [
      ...recurringList.slice(0, index),
      item,
      ...recurringList.slice(index + 1),
    ];
    setRecurringList(list);
  };

  const handleRecurringItemDelete = (index) => {
    const list = [
      ...recurringList.slice(0, index),
      ...recurringList.slice(index + 1),
    ];
    setRecurringList(list);
  };

  const handleAddCryptoClick = () => {
    const ccy = coinOptions[0].value;
    const allocation = Math.floor(100 / (recurringList.length + 1));
    const list = [
      ...recurringList.map((item) => {
        return { ...item, allocation };
      }),
      { ccy, allocation },
    ];

    setRecurringList(list);
  };

  const handleSubmit = async () => {
    const frequency = recurringForm.getFieldValue("frequency");
    const localTime = recurringForm.getFieldValue("local_time");
    const amount = recurringForm.getFieldValue("investment_amount");

    const [period, periodValue] = frequency.split("-");

    const data = {
      stgyName: "Strategy 1",
      recurringList: recurringList.map(({ ccy, allocation }) => {
        return { ccy, ratio: (allocation / 100).toFixed(2) };
      }),
      period: period,
      amt: amount,
      recurringTime: localTime,
      timeZone: (new Date().getTimezoneOffset() / -60).toString(),
      investmentCcy: "USDT",
      tdMode: "cash",
    };

    switch (period) {
      case "monthly":
      case "weekly":
        data.recurringDay = periodValue;
        break;
      case "hourly":
        data.recurringHour = periodValue;
    }

    try {
      await createRecurringBuyOrder(data).unwrap();
      toast.success("Bot created");
    } catch (error) {
      console.error(error);
      toast.error(error?.msg || error?.data?.message);
    }
  };

  return (
    <StyledRecurringBuy>
      <Form form={recurringForm} onFinish={handleSubmit} className="text-black">
        <div className="field-container">
          <div className="allocation-header">
            <label>1. Crypto Allocation</label>
            <p style={{ margin: 0 }}>
              Remained{" "}
              <span
                className={
                  remainingAllocation === 0 ? "text-black" : "text-red"
                }
              >
                {remainingAllocation}%
              </span>{" "}
              / 100%
            </p>
          </div>
          {recurringList.map((item, index) => (
            <RecurringItem
              key={index}
              index={index}
              item={item}
              coinOptions={coinOptions}
              onChange={handleRecurringItemChange}
              onDelete={handleRecurringItemDelete}
              deleteDisabled={recurringList.length === 1}
            />
          ))}
          <Button
            className="button"
            onClick={handleAddCryptoClick}
            icon={<PlusOutlined />}
          >
            Add Crypto
          </Button>
        </div>
        <div className="field-container">
          <label>2. Recurring period</label>
          <Form.Item
            name="frequency"
            rules={[{ required: true, message: "Please select frequency" }]}
          >
            <TreeSelect
              placeholder="Frequency"
              prefix={<span>Frequency</span>}
              treeData={FREQUENCY_OPTIONS}
            />
          </Form.Item>
          <Form.Item
            name="local_time"
            rules={[{ required: true, message: "Please select local time" }]}
          >
            <Select placeholder="Local Time" options={LOCAL_TIME_OPTIONS} />
          </Form.Item>
          {/* <span>The first purchase cycle starts on 01/03/2024 13:00</span> */}
        </div>
        <div className="field-container">
          <label>2. Amount</label>
          <Form.Item
            name="investment_amount"
            rules={[
              { required: true, message: "Please enter investment amount" },
            ]}
          >
            <Input
              type="number"
              className="input"
              prefix={<span>Investment Amount</span>}
              suffix={<span>USDT</span>}
            />
          </Form.Item>
          <Button
            htmlType="submit"
            className="submit-btn"
            loading={isCreatingRecurringBuyOrder}
          >
            Create
          </Button>
          <span>
            Available{" "}
            {accountInfo?.data?.find((d) => d.ccy === "USDT")?.availBal} USDT
          </span>
        </div>
      </Form>
    </StyledRecurringBuy>
  );
};

export default RecurringBuy;
