import { Form, Input, Select } from "antd";
import { StyledRecurringItem } from "./RecurringItem.styles.js";
import { DeleteOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";

const RecurringItem = ({
  index,
  item,
  coinOptions,
  onChange,
  onDelete,
  deleteDisabled,
}) => {
  const handleAllocationChange = (allocation) => {
    onChange(index, { ...item, allocation: Number(allocation) });
  };

  const handleCurrencyChange = (currency) => {
    onChange(index, { ...item, ccy: currency });
  };

  return (
    <StyledRecurringItem>
      <Form.Item
        style={{ width: "100%" }}
        name={`alloc_${index}`}
        rules={[{ required: true, message: "Allocation can't be empty" }]}
      >
        <Input
          type="number"
          className="input"
          prefix={
            <Select
              style={{ width: 100 }}
              options={coinOptions}
              // optionRender={(option) => {
              //   console.log(option, 'OPTIONS');
              //   return (
              //     <label>
              //       <img src={option.data.icon} width={20} height={20} />
              //       <span style={{ marginLeft: 10 }}>{option.label}</span>
              //     </label>
              //   );
              // }}
              value={item.ccy}
              onClick={(e) => e.stopPropagation()}
              onChange={handleCurrencyChange}
              showSearch
            />
          }
          suffix={
            <div className="suffix-button-container">
              <button
                type="button"
                className="suffix-button"
                onClick={() => handleAllocationChange(item.allocation + 1)}
              >
                <UpOutlined
                  style={{ height: "10px", width: "10px" }}
                  size={10}
                />
              </button>
              <hr />
              <button
                type="button"
                className="suffix-button"
                onClick={() => handleAllocationChange(item.allocation - 1)}
              >
                <DownOutlined
                  style={{ height: "10px", width: "10px" }}
                  size={10}
                />
              </button>
            </div>
          }
          min={0}
          value={item.allocation}
          onChange={(e) => handleAllocationChange(e.target.value)}
        />
      </Form.Item>
      <button
        type="button"
        className={`dynamic-delete-button ${deleteDisabled && "disabled"}`}
        onClick={() => onDelete(index)}
        disabled={deleteDisabled}
      >
        <DeleteOutlined />
      </button>
    </StyledRecurringItem>
  );
};

export default RecurringItem;
