import React from "react";
import { StyledNotFound } from "./NotFound.styles";

const NotFound = () => {
  return (
    <StyledNotFound>
      <span>404 | Not Found</span>
    </StyledNotFound>
  );
};

export default NotFound;
