import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { checkIfLogin } from "../Common/Common";
import { ROUTES } from "../Constants/Routes";

function KycRoute() {
  const kycVerified = JSON.parse(localStorage.getItem("kycVerified"));
  return !kycVerified && checkIfLogin() ? (
    <Outlet />
  ) : (
    <>
      <Navigate to={ROUTES.HOME} />
    </>
  );
}

export default KycRoute;
