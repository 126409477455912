import styled from "styled-components";

export const StyledRecurringItem = styled.div`
  display: flex;

  .input {
    padding: 0;
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black} !important;
  }

  input {
    background: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.black};
  }

  .ant-input {
    height: 100%;
  }

  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
  }

  .ant-select-single .ant-select-selector {
    background: ${(props) => props.theme.colors.white};
    height: auto !important;
  }

  .suffix-button-container {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 10px 10px 0;
    overflow: hidden;
  }

  .suffix-button {
    width: 24px;
    background-color: ${(props) => props.theme.colors.white};
    border: none;
  }

  .suffix-button:hover {
    color: blue;
  }

  .dynamic-delete-button {
    color: ${(props) => props.theme.colors.black};
    margin-left: 10px;
    font-size: 20px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
  }

  .disabled {
    color: grey;
    cursor: not-allowed;
  }
`;
