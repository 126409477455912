import styled from "styled-components";

export const StyledNotFound = styled.div`
  height: 75vh;
  background: ${(props) => props.theme.colors.blue.extraLight};
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  span {
    width: 100%;
    margin: auto;
    font-size: 40px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.black};
  }
`;
