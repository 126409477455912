import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const okxApi = createApi({
  reducerPath: "okxApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://www.okx.com/api/v5",
  }),
  endpoints: (builder) => ({
    getTokens: builder.query({
      query: (params) => {
        return {
          url: `/market/tickers`,
          params,
        };
      },
      transformResponse: (response) => {
        const tokens = new Set();
        response?.data?.forEach((ticker) => {
          const [t1, t2] = ticker.instId.split("-");
          tokens.add(t1);
          tokens.add(t2);
        });
        return Array.from(tokens);
      },
    }),
  }),
});

export const { useGetTokensQuery } = okxApi;
