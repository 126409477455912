import { io } from "socket.io-client";
import { AppLogo } from "./Assets/Svg/Applogo";

// config object getting keys from env
export const config = {
  APP_NAME: process.env.REACT_APP_NAME,
  APP_LOGO: ({ ...props }) => {
    return <AppLogo {...props} />;
  },
  LOGIN_LOGO: process.env.REACT_APP_LOGIN_LOGO,
  BASE_URL: process.env.REACT_APP_BASE_URL,
  MARKET_API_BASE_URL: process.env.REACT_APP_MARKET_API_BASE_URL,
  GEETEST_KEY: process.env.REACT_APP_GEETEST_KEY,
  IP_API: process.env.REACT_APP_IP_API,
  KYC_BASE_URL: process.env.REACT_APP_KYC_BASE_URL,
  TRANSACTION_BASE_URL: process.env.REACT_APP_TRANSACTION_BASE_URL,
  WEBSOCKET_BASE_URL: process.env.REACT_APP_WEBSOCKET_BASE_URL,
  SWAP_BASE_URL: process.env.REACT_APP_SWAP_BASE_URL,
  PERP_WEBSOCKET_BASE_URL: process.env.REACT_APP_PERP_WEBSOCKET_BASE_URL,
  CANDLE_STICK_URL: process.env.REACT_APP_CANDLESTICK_SOCKET,
  BOT_BASE_URL: process.env.REACT_APP_BOT_BASE_URL,
  BOT_WEBSOCKET_BASE_URL: process.env.REACT_APP_BOT_WEBSOCKET_BASE_URL,
  AES_ENC_KEY: process.env.REACT_APP_AES_ENC_KEY,
};

export const connectSocket = (uri) => {
  return io(uri, {
    transports: ["websocket"],
  });
};
