import styled from "styled-components";

export const StyledRecurringBuy = styled.div`
  .text-black {
    color: ${(props) => props.theme.colors.black};
  }

  .text-red {
    color: red;
  }

  .allocation-header {
    display: flex;
    justify-content: space-between;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 35px;
    max-width: 720px;
    margin: auto;
  }

  .field-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  input {
    height: 40px;
    width: 100% !important;
    text-align: right !important;
    padding-right: 30px;
  }

  .ant-modal-content,
  .ant-modal-header .ant-modal-title,
  .ant-modal .ant-modal-header,
  .ant-select-selector,
  .ant-modal-close-x,
  .ant-input-affix-wrapper,
  .ant-select-selection-placeholder,
  .ant-btn,
  input {
    background: ${(props) => props.theme.colors.white} !important;
    color: ${(props) => props.theme.colors.black} !important;
  }

  .ant-select-arrow {
    color: ${(props) => props.theme.colors.grey.dark} !important;
  }

  .ant-form-item {
    margin: 0 !important;
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
    }
  }

  .ant-btn {
    width: 100% !important;
    margin: auto;
    border-radius: 20px;
    height: 40px;
    span {
      padding: 2px;
    }
  }

  .submit-btn {
    background: ${(props) => props.theme.colors.blue.dark} !important;
    color: ${(props) => props.theme.colors.whiteOnly} !important;
    font-weight: ${(props) => props.theme.fontWeight.semiLight};
    font-size: ${(props) => props.theme.typography.text2};
    border: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
