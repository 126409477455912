export const theme = {
  light: {
    colors: {
      aiCardCopyBackground: "#F7F8FA",
      black: "#27282C",
      white: "#FFFFFF",
      whiteOnly: "#FFFFFF",
      tableHover: "#FFFFFF",
      dotsColor: "#dddddd",
      blackOnly: "#000000",
      whiteBlack: "#FFFFFF",
      marketUp: "#008563",
      rating: "#e96932",
      scrollThumb: "#888",
      scroll2: "#555",
      blend: "#F4FAFF",
      greenShade: "#0EAD98",
      blendWhite: "#f4faff",
      darkBlack: "rgb(247, 248, 250)",
      blendBlue: "rgb(48,148,234,0.2)",
      aiStar: "#FFD700",
      tradeHeading: "#2c3ca7",
      tradingBottomGradient: `linear-gradient(
        90deg,
        #dac4ff 0%,
        #c5acf2 51.04%,
        rgba(225, 240, 255, 0) 100%
      )`,
      moreServicesBg: "#FFFFFF",
      marketDown: "#DB5541",
      cardbg: "#FFFFFF",
      sliderbg: "#E6E5E9",
      withdrawContainer: "#F7F8FA",
      graphFilter: "#F7F8FA",
      cardhead: "rgba(123, 116, 133, 0.04)",
      backdrop: "rgba(0, 0, 0, 0.5)",
      chartBackgroundColor: "rgba(244, 250, 255, 0.43)",
      pagination: "#F2F2F2",
      logout: "#BCC2CA",
      bottomGradient: `linear-gradient(
        90deg,
        #2859fa 0%,
        #4893fd 51.04%,
        #6bb5fc 100%
      )`,
      tableHead: "rgba(123, 116, 133, 0.04)",
      marketUpGraphGradient: "rgba(0, 133, 99, 0.5)",
      marketDownGraphGradient: "rgba(219, 85, 65, 0.5)",
      marketTableHeadGradient:
        "linear-gradient(90deg, rgba(220, 220, 220, 0.0768) 0%, rgba(123, 116, 133, 0.08) 49.35%, rgba(123, 116, 133, 0) 102.29%)",
      placeHolderColor: "#BFC1C3",
      dividerColor: "#EEEEEE",
      grey: {
        dark: "#7D7F81",
        semiLight: "#F7F8FA",
        medium: "#27282C",
        semiLightDark: "#F7F8FA",
        greyish: "#e0dee1",
        light: "#bfc1c3",
        mediumLight: "#2C2F36",
        shade1: "#707580",
        lightTransparent: "#FFFFFF",
        semiDark: "#7B7485",
        transparent: "rgba(211, 211, 211, 0.2)",
        sliderTrack: "#E6E5E9",
        extraLight: "#F3F3F3",
      },
      blue: {
        dark: "#3094ea",
        semiDark: "#88C2f4",
        extraLight: "#f4faff",
        extraDark: "#F4FAFF",
        shade1: "#8B97FE",
        shade2: "#e3effd99",
        blueShade: "#3a0e83",
        light: "#FAFBFC",
        gradient: "rgba(48, 148, 234, 0.12)",
        gradient1: "rgba(48, 148, 234, 0.12)",
        gradient2:
          "linear-gradient(156.85deg,#f4faff 0%, rgba(190, 222, 249, 0.42) 31.42%, rgba(48, 148, 234, 0.52) 100% )",
        gradient3: "#F4FAFF",
        gradient4: "linear-gradient(180deg, #f4fafc 0%, #ffffff 120.18%)",
      },
    },
    fonts: ["sans-serif", "Roboto"],
    typography: {
      title1: "24px",
      title2: "28px",
      title3: "32px",
      title4: "36px",
      title5: "42px",
      title6: "52px",
      title7: "40px",
      title8: "46px",
      subTitle1: "20px",
      subTitle2: "22px",
      subTitle3: "24px",
      subTitle4: "26px",
      text: "14px",
      text1: "16px",
      text2: "18px",
      subText1: "11px",
      subText2: "12px",
      subText3: "13px",
      subText4: "14px",
      subText5: "15px",
    },
    fontWeight: {
      semiLight: 400,
      light: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    shadows: {
      shadow1: "0px 0px 15px rgba(0, 0, 0, 0.05)",
    },
    borders: {
      border1: "1px solid rgba(123, 116, 133, 0.2)",
      border2: "0.5px solid #BFC1C3",
      border3: "0.75px solid #ECECEC",
      border4: "rgba(123, 116, 133, 0.4)",
      border5: "1px solid #ECECEC",
      border6: "1px solid #7B7485",
      border7: "#d9d9d9",
    },
    hovers: {
      hover1: "#FFFFFF",
      hover2: "#FFFFFF",
    },
    images: {
      bgImage: 'url("/Logo/Icons/background.svg") no-repeat center',
    },
  },
  dark: {
    colors: {
      aiCardCopyBackground: "#23272E",
      black: "#FFFFFF",
      white: "#23272E",
      whiteOnly: "#FFFFFF",
      blackOnly: "#000000",
      tableHover: "#333842",
      tradeHeading: "#2c3ca7",
      scroll2: "#555",
      whiteBlack: "#171A1F",
      scrollThumb: "#888",
      greenShade: "#0EAD98",
      darkBlack: "#16171A",
      tradingBottomGradient: `linear-gradient(
        90deg,
        #dac4ff 0%,
        #c5acf2 51.04%,
        rgba(225, 240, 255, 0) 100%
      )`,
      dotsColor: "#dddddd",
      blend: "rgba(23, 26, 31, 0.3)",
      cardbg: "#1F2229",
      sliderbg: "#1F2229",
      blendWhite: "#23272E",
      blendBlue: "rgb(48,148,234,0.2)",
      marketUp: "#008563",
      rating: "#e96932",
      moreServicesBg: "#1A1E24",
      graphFilter: "#292C32",
      bottomGradient: `linear-gradient(
        90deg,
        #2859fa 0%,
        #4893fd 51.04%,
        #6bb5fc 100%
      )`,
      logout: "#BCC2CA",
      aiStar: "#FFD700",
      withdrawContainer: "#23272E",
      cardhead: "rgb(35, 39, 46)",
      marketDown: "#DB5541",
      backdrop: "rgba(0, 0, 0, 0.5)",
      chartBackgroundColor: "rgba(244, 250, 255, 0.43)",
      pagination: "#F2F2F2",
      placeHolderColor: "#c4c6cf",
      tableHead: "rgba(123, 116, 133, 0.04)",
      marketUpGraphGradient: "rgba(0, 133, 99, 0.5)",
      marketTableHeadGradient: "#171A1F",
      marketDownGraphGradient: "rgba(219, 85, 65, 0.5)",
      dividerColor: "#ffffff",

      grey: {
        dark: "#7D7F81",
        semiLight: "#F7F8FA",
        semiLightDark: "#27282C",
        light: "#26272C",
        medium: "#BABDC2",
        mediumLight: "#2C2F36",
        lightTransparent: "#23272E",
        greyish: "#e0dee1",
        shade1: "#707580",
        semiDark: "#909399",
        transparent: "rgba(211, 211, 211, 0.2)",
        sliderTrack: "#E6E5E9",
        extraLight: "#F3F3F3",
      },
      blue: {
        dark: "#3094ea",
        semiDark: "#88C2f4",
        extraLight: "#171A1F",
        shade1: "#8B97FE",
        shade2: "#e3effd99",
        extraDark: "rgba(48, 148, 234, 0.4)",
        blueShade: "#3a0e83",
        light: "#23272E",
        lightTransparent: "#23272E",
        gradient: "rgba(48, 148, 234, 0.12)",
        gradient1: "#171A1F",
        gradient2: " linear-gradient(180deg, #3094EA 0%, #004E92 100%)",
        gradient3:
          "linear-gradient(180.1deg, rgba(48, 148, 234, 0.4) 0.09%, rgba(23, 26, 31, 0.08) 41.28%, #171A1F 90.97%)",
        gradient4: "#f4faff",
      },
    },
    fonts: ["sans-serif", "Roboto"],
    typography: {
      title1: "24px",
      title2: "28px",
      title3: "32px",
      title4: "36px",
      title5: "42px",
      title6: "52px",
      title7: "40px",
      title8: "46px",
      subTitle1: "20px",
      subTitle2: "22px",
      subTitle3: "24px",
      subTitle4: "26px",
      text: "14px",
      text1: "16px",
      text2: "18px",
      subText1: "11px",
      subText2: "12px",
      subText3: "13px",
      subText4: "14px",
      subText5: "15px",
    },
    fontWeight: {
      semiLight: 400,
      light: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
    },
    borders: {
      border1: "1px solid rgba(123, 116, 133, 0.2)",
      border2: "0.5px solid #26272C",
      border3: "0.75px solid transparent",
      border4: "rgba(123, 116, 133, 0.4)",
      border5: "1px solid transparent",
      border6: "1px solid #7B7485",
      border7: "white",
    },
    hovers: {
      hover1: "rgb(61 65 72)",
      hover2: "rgb(61, 65, 72, 0.5)",
    },
    images: {
      bgImage: 'url("/Logo/Icons/darkbackground.svg") no-repeat center',
    },
  },
};
