import { useTheme } from "styled-components";

export const ProfilePicture = ({ height = 72, width = 72 }) => {
  const theme = useTheme();
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36 0C16.15 0 0 16.15 0 36C0 55.85 16.15 72 36 72C55.85 72 72 55.85 72 36C72 16.15 55.85 0 36 0ZM36 8.471C43.5591 8.471 49.7081 14.6217 49.7081 22.1809C49.7081 29.7394 43.5591 37.7623 36 37.7623C28.4409 37.7623 22.2919 29.7394 22.2901 22.1809C22.2901 14.6217 28.4409 8.471 36 8.471ZM36 67.7653C25.5201 67.7653 16.2286 62.6877 10.4429 54.8613C10.9334 46.2603 17.3636 39.2527 25.7086 37.9041C28.3676 39.9299 31.6851 41.1363 35.2789 41.1363H36.722C40.3157 41.1363 43.6333 39.9298 46.2934 37.9041C54.6371 39.2529 61.0677 46.2601 61.5591 54.8613C55.7729 62.6871 46.4806 67.7653 36.0006 67.7653H36Z"
        fill={theme.colors.blue.dark}
      />
      <circle
        cx="35.947"
        cy="40.0178"
        r="31.9821"
        fill={theme.colors.blue.dark}
        fillOpacity="0.2"
      />
    </svg>
  );
};
